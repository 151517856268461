<template>
  <div class="setting">
    <div class="left_account">
      <div>
        <p style="font-size: large">{{ $t("message.email.currentaccount") }}</p>
      </div>
      <div class="card">
        <accountcard
          @deleteaccount="deleteaccount"
          :dataObj="emailAccountForm"
          :temp="3"
        ></accountcard>
      </div>
      <div>
        <p style="font-size: large">{{ $t("message.email.accountlist") }}</p>
      </div>
      <div style="overflow-y: auto; height: 500px">
        <ul>
          <li v-for="(item, index) in accountandemail" :key="item.index">
            <accountcard
              @deleteaccount="deleteaccount"
              v-if="item.id != emailAccountForm.id"
              :dataObj="item"
            >
            </accountcard>
          </li>
        </ul>
      </div>
    </div>
    <div class="right_set">
      <el-form>
        <div>
          <p style="font-size: large">{{ $t("message.email.extractemail") }}</p>
        </div>
        <div class="card">
          <!--<template #header>-->
          <!--<div class="card-header">-->
          <!--<p style="font-size: larger;">Internet电子邮件设置</p>-->
          <!--<p>&emsp;&emsp;这些都是使电子邮件账户正常运行的必须设置</p>-->
          <!--</div>-->
          <!--</template>-->
              <el-card class="box-card">
                <!-- <el-card style="height: 485px"> -->
                  <el-row>
                    <!-- <el-col> -->
                      <p>
                        {{ $t("message.email.MailType") }}
                      </p>
                    <!-- </el-col> -->
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-form-item label-width="50px">
                        <el-radio-group v-model="extractForm.emailtype">
                          <el-radio label="all">ALL</el-radio>
                          <el-radio label="tonnage">TONNAGE</el-radio>
                          <el-radio label="cargo">CARGO</el-radio>
                          <el-radio label="tct">TCT</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-form-item
                        :label="$t('message.email.mailtheme')"
                        label-width="100px"
                      >
                        <el-autocomplete
                          v-model="extractForm.subject"
                          style="width: 40%" :trigger="'manual'"
                        >
                        </el-autocomplete>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-form-item
                        :label="$t('message.email.sender')"
                        label-width="100px"
                      >
                        <el-autocomplete
                          v-model="extractForm.sender"
                          style="width: 40%"
                        >
                        </el-autocomplete>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-form-item
                        :label="$t('message.email.recipient')"
                        label-width="100px"
                      >
                        <el-autocomplete
                          v-model="extractForm.receiver"
                          style="width: 40%"
                        >
                        </el-autocomplete>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-form-item
                        :label="$t('message.email.sendtime')"
                        label-width="100px"
                      >
                        <el-date-picker
                          style="width: 80%"
                          v-model="daterange"
                          type="daterange"
                          value-format="”yyyy-MM-dd”"
                          range-separator="~"
                          :start-placeholder="$t('message.email.StartTime')"
                          :end-placeholder="$t('message.email.EndTime')"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8"
                      ><el-button
                        size="medium"
                        type="primary"
                        style="width: 100px; margin-left: 20%"
                        @click="extractMail"
                        >{{ $t("message.email.extractemail") }}</el-button
                      ></el-col
                    >
                    <!-- <el-col :span="8"
                      ><el-button
                        size="medium"
                        type="primary"
                        style="width: 100px; margin-left: 10%"
                        @click="StopExtract"
                        >{{ $t("message.email.stop") }}</el-button
                      ></el-col
                    > -->
                    <el-col :span="8"
                      ><el-button
                        size="medium"
                        type="primary"
                        style="width: 100px; margin-left: 5%"
                        @click="reset"
                        >{{ $t("message.email.reset") }}</el-button
                      ></el-col
                    >
                  </el-row>
                <!-- </el-card> -->
              </el-card>
            <!-- jx 注释掉24.2.2，修改进度条规则 -->
            <!-- <el-col :span="12">
              <div class="col_box" v-if="extractflag == true">
                <el-card>
                  <el-row>
                    <p style="font-size: large">
                      {{ $t("message.email.ExtractProgressMeter") }}
                    </p>
                  </el-row>
                  <el-row>
                    <p style="font-size: medium">&emsp;&emsp;TONNAGE</p>
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-form-item label-width="50px">
                        <el-row>
                          <el-col :span="2">0</el-col>
                          <el-col :span="20"
                            ><el-progress :percentage="50"></el-progress
                          ></el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <p>
                      &emsp;&emsp;&emsp;{{ $t("message.email.ExtractTips") }}
                    </p>
                  </el-row>
                  <el-row>
                    <p style="font-size: medium">&emsp;&emsp;CARGO</p>
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-form-item label-width="50px">
                        <el-row>
                          <el-col :span="2">0</el-col>
                          <el-col :span="20"
                            ><el-progress :percentage="50"></el-progress
                          ></el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <p>
                      &emsp;&emsp;&emsp;{{ $t("message.email.ExtractTips") }}
                    </p>
                  </el-row>
                  <el-row>
                    <p style="font-size: medium">&emsp;&emsp;TCT</p>
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-form-item label-width="50px">
                        <el-row>
                          <el-col :span="2">0</el-col>
                          <el-col :span="20"
                            ><el-progress :percentage="50"></el-progress
                          ></el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <p>
                      &emsp;&emsp;&emsp;{{ $t("message.email.ExtractTips") }}
                    </p>
                  </el-row>
                </el-card>
              </div>
            </el-col> -->
        </div>
        <!-- jx add 24.1.30 新增显示抽取状态界面 -->
        <div class="card" v-if="extractflag == true">
          <el-form :model="downloadForm" ref="downloadForm">
            <el-card class="box-card">
              <el-row>
                <p>{{ $t("message.email.extractmessage") }}</p>
              </el-row>
              <el-row>
                <el-col>
                  <el-form-item label-width="50px">
                    <div style="height: 100px; width: 100%" v-loading="loading">
                      <span>{{ extractForm.message }}</span>
                    </div>
                    <el-progress
                      v-show="progressflag"
                      :percentage="percentage"
                    ></el-progress>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row >
                <el-col :span="19"
                  ><el-button
                    size="medium"
                    type="success"
                    style="margin-left: 70%"
                    @click="continuedown"
                    :disabled="isButtonDisabled || isBothButtonsDisabled"
                    >{{ $t("message.email.continue") }}</el-button
                  ></el-col
                >
                <el-col :span="2"
                  ><el-button
                    size="medium"
                    type="danger"
                    style="width: 100px"
                    :disabled="!isButtonDisabled || isBothButtonsDisabled"
                    @click="StopExtract"
                    >{{ $t("message.email.pause") }}</el-button
                  ></el-col
                >  
                
              </el-row> 
            </el-card>
          </el-form>
        </div>
        <!-- ********************************** -->
      </el-form>
    </div>
  </div>
</template>

<script>
import accountcard from "../../components/account_email/Accountcard";
//import EmailDownloadVue from "./EmailDownload.vue";
import {
  getinfouseraccount,
  getemailaccount,
  emailextract,
  emailextractstatus,
  /*jx add 24.2.2 邮件抽取的停止接口借用邮件下载的停止接口*/
  emailstopdownload,
} from "../../api/emailextract";

export default {
  components: { accountcard },
  data() {
    return {
      isButtonDisabled: false,
      isBothButtonsDisabled:false,
      downloadflag: "false",
      extractflag: "false",
      /*jx add */
      progressflag: false,  // 是否显示抽取的标记位
      percentage:0,         // 抽取进度百分比
      extracttimer:null,    // 定时器用于定时获取抽取状态
      taskid:"",            // 任务id
      /**/
      bar: "",
      accountandemail: [],
      emailAccountForm: "",
      daterange: "",
      extractForm: {
        creator_id: "",
        emailaccount_id: "",
        emailtype: "all",
        subject: "",
        sender: "",
        receiver: "",
        send_time_begin: "",
        send_time_end: "",
        message:""
      },
      options: [
        {
          value: "选项1",
          label: "今天",
        },
        {
          value: "选项2",
          label: "昨天",
        },
        {
          value: "选项3",
          label: "前天",
        },
        {
          value: "选项4",
          label: "7天内",
        },
        {
          value: "选项5",
          label: "自定义",
        },
      ],
    };
  },
  methods: {
    timeFormat(date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1 + "";
      month = month.length === 1 ? "0" + month : month;
      let day = date.getDate() + "";
      day = day.length === 1 ? "0" + day : day;
      return year + "-" + month + "-" + day;
    },
    opendown() {
      this.downloadflag = true;
    },
    openextract() {
      this.extractflag = true;
    },
    getMailList() {
      getemailaccount().then((response) => {
        if (response.error_code == 200) {
          this.accountandemail = response.data.results;
        }
      });
    },
    deleteaccount() {
      this.getMailList();
    },
    /*jx 注释掉抽取的原始代码*/
    // extractMail() {
    //   this.extractForm.send_time_begin = this.daterange[0]
    //     ? this.timeFormat(this.daterange[0])
    //     : null;
    //   this.extractForm.send_time_end = this.daterange[1]
    //     ? this.timeFormat(this.daterange[1])
    //     : null;
    //   this.extractForm.creator_id = this.emailAccountForm.creator_id;
    //   this.extractForm.emailaccount_id = this.emailAccountForm.id;
    //   emailextract(this.extractForm).then((response) => {
    //     if (response.error_code == 200) {
    //       console.log(response);
    //       this.$message.success(response.data.message);
    //     }
    //   });
    // },
    /*jx modify 24.1.20 修改邮件抽取功能：原始版本没有获取状态。改成异步，启动定时器获取抽取状态，与下载逻辑一样*/
    async extractMail() 
    {
      this.isButtonDisabled = true;
      this.extractForm.send_time_begin = this.daterange[0]
        ? this.timeFormat(this.daterange[0])
        : null;
      this.extractForm.send_time_end = this.daterange[1]
        ? this.timeFormat(this.daterange[1])
        : null;
      this.extractForm.creator_id = this.emailAccountForm.creator_id;
       this.extractForm.emailaccount_id = this.emailAccountForm.id;
     // this.extractForm.emailaccount_id = 109;
      await emailextract(this.extractForm).then((response) => 
      {
        if (response.error_code == 200) 
        {
          this.openextract();
          console.log(response);
          // this.$message.success(response.data.message);
          if (response.data.totals == 0) 
          {
            this.progressflag = false;
            this.extractForm.message = '没有可以识别的邮件，请更改条件后重新筛选'
            // this.$message.error(mess);
          } 
          else 
          {
            this.progressflag = true;
            let ExtractTime = response.data.totals;
            this.extractForm.message = "Emailaccount is parseing ! Taking "+ExtractTime+ " minutes to parse "+response.data.totals+" emails in mailbox .Please  wait for the finish notification ! "
            // this.extractForm.message = response.data.message;
            if (response.data.taskid) 
            {
              this.extractForm.taskid =  response.data.taskid;
              let ExtractResponse;
              this.extracttimer = setInterval(() => 
              {
                emailextractstatus(response.data.taskid).then((ExtractResponse)=>
                {
                  this.percentage = ExtractResponse.data.progress;
                  if(ExtractResponse.data.status == "SUCCESS")
                  {
                    this.isBothButtonsDisabled=true;
                    clearInterval(this.extracttimer);
                    let mess = '抽取完成，共计'+ExtractResponse.data.totals+'封邮件'
                    this.$message.success(mess);
                  }
                })
              },1000)
            }
          }
        }
        if(response.error_code == 100)
        {
          console.log(response);
          this.$message.error(response.error_msg);
        }
      })
    },
    continuedown()
    {
      if (!this.downloadComplete) {
      // 处理继续下载逻辑
      // ...

      // 禁用继续按钮，启用停止按钮
      this.isButtonDisabled = true;
    }
      this.isButtonDisabled = true;
      
    },
    /********************************************/
    /*jx add 24.1.10 新增功能：停止邮件抽取*/
    async StopExtract() 
    {
      this.isButtonDisabled = false;
    this.downloadComplete = true;
      /*jx add 立即停止对下载状态的请求*/
      clearInterval(this.extracttimer);
      this.extracttimer = null;
      // console.log("人工stop");
      /**/
      try {
        // 调用发送停止下载请求的函数，并传递相应的参数,构造停止下载请求的参数
        const message ={
          sendmail_date:"stop",
          emailaccount_id:this.extractForm.taskid,
          download_type:"",
        };
        const response = await emailstopdownload(message);
        const msg = response.data.body;
        var vmsg = JSON.parse(msg);
        console.log("responddsdsdssdddddddddd"+vmsg.error_msg);
        // 判断后端返回的错误码
        if (vmsg.error_code === 1) {
          // 终止成功，显示成功消息
          this.$message.success(vmsg.error_msg);
        } else {
          // 终止失败，显示错误消息
          this.$message.error(vmsg.error_msg);   
        }
      } catch (error) {
        // 处理请求失败的情况，显示错误消息
        this.$message.error('请求失败，请稍后重试');
        console.error(error);
      }
    },
    /*********************/
    //重置
    reset() {
      // 重置与抽取邮件表单相关的数据
      this.extractForm = {
        creator_id: "",
        emailaccount_id: "",
        emailtype: "",
        subject: "",
        sender: "",
        receiver: "",
        send_time_begin: "",
        send_time_end: "",
      };
      // 清空时间选择相关的数据
      this.daterange = "";
    },
  },
  
  created() {
    this.currentId = this.$route.params.id;
    getinfouseraccount(this.currentId).then((response) => {
      if (response.error_code == 200) {
        this.emailAccountForm = response.data;
      }
    });
    this.getMailList();
  },
};
</script>

<style lang="scss">
.setting {
  height: 100%;
  width: 100%;

  .el-input__inner {
    height: 30px;
  }

  .left_account {
    font-size: larger;
    float: left;
    width: 35%;
    height: 100%;

    .card {
      margin-bottom: 10%;
    }
  }

  .right_set {
    margin-left: 36%;
    width: 60%;
    height: 100%;

    .el-input__inner {
      height: 30px;
    }

    .el-card__body {
      padding: 10px;
    }

    .col_box {
      margin: 3%;
      background-color: rgba(212, 219, 208, 0.28);
    }

    .el-progress {
      margin-top: 12px;
    }
  }
}
</style>
